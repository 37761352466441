import React from 'react'
import { Helmet } from 'react-helmet'
import CaseStudyLayout from '../../../components/caseStudyLayout'
import * as styles from './styles.module.css'
import { graphql } from 'gatsby'

import logoColored3x from './img/logo-colored@3x.png'
import logo3x from './img/logo@3x.png'
import robHeadshot from './img/rob-headshot.png'

const SilverSneakers = ({ data }) => {
  return (
    <>
      <CaseStudyLayout {...data}>
        <Helmet bodyAttributes={{ class: styles.ssgoBody }}>
          <script
            rel="text/javascript"
            src="https://kit.fontawesome.com/aed055a1d4.js"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Nunito+Sans:700,900&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400,800&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <div className={styles.ssgo}>
          <div className={styles.ssgoWrapper}>
            <div className={styles.heroContainer}>
              <div
                className={`${styles.heroFirstWaveMobile} ${styles.waveContainerMobile} ${styles.hdDesktop}`}
              >
                <svg height="100%" width="100%" viewBox=" 0 0 375 198">
                  <defs>
                    <filter
                      id="f10"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <g fill="white" fillRule="evenodd" filter="url(#f10)">
                    <path d="M0,0 L0,167.838563 C75.8952964,171.261209 200.895296,180.765145 375,196.350372 L375,0 L0,0 Z"></path>
                  </g>
                </svg>
              </div>
              <div>
                <div className={styles.heroWrapper}>
                  <div className={styles.contentContainer}>
                    <h2>Case Study</h2>
                    <img className={styles.hdDesktop} src={logoColored3x} />
                  </div>
                  <div className={styles.heroContent}>
                    <div className={styles.heroIphoneContainer}></div>
                    <div className={styles.contentContainer}>
                      <div className={styles.heroDescription}>
                        <img
                          className={styles.hdMobile}
                          src={logo3x}
                          width="314"
                        />
                        <h3>Healthier Seniors through Technology</h3>
                        <p>
                          SilverSneakers was a household name for the
                          fitness-conscious-over-65 crowd for several decades
                          when parent company Tivity Health approached us to
                          partner with them to develop their first
                          consumer-facing mobile application. Armed with years
                          of deep understanding of their customers' unique
                          needs, we worked closely with internal teams at Tivity
                          Health and with their customers to design and build a
                          companion app for iOS and Android devices to extend
                          the brand beyond its core offering of access to
                          fitness locations across the country.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.waveContainer}>
                <svg height="100%" width="100%" viewBox=" 0 0 1440 235">
                  <defs>
                    <filter
                      id="f1"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="-20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <path
                    d="M0.99577167,116.204886 C61.7612871,138.424342 139.929363,151.79025 235.5,156.302611 C541.97389,166.566355 760.333461,0 1013.23335,0 C1292.49289,0 1221.37079,170.167271 1441,235.583919 L1441,400 L0.99577167,400 L0.99577167,116.204886 Z"
                    id="Path-3-Copy"
                    fill="white"
                    filter="url(#f1)"
                  >
                    <animate
                      dur="7s"
                      repeatCount="indefinite"
                      attributeName="d"
                      values="M0.99577167,116.204886 C61.7612871,138.424342 139.929363,151.79025 235.5,156.302611 C541.97389,166.566355 760.333461,0 1013.23335,0 C1292.49289,0 1221.37079,170.167271 1441,235.583919 L1441,400 L0.99577167,400 L0.99577167,116.204886 Z;M0,116.204886 C58.1158492,153.33243 136.283925,166.698338 234.504228,156.302611 C530.740293,116.204886 759.33769,0 1012.23758,0 C1291.49712,0 1306.11172,116.204886 1440.00423,235.583919 L1440.00423,400 L0,400 L0,116.204886 Z;M0,116.204886 C43.7587399,153.501924 113.423428,174.406623 208.994065,178.918983 C515.467955,189.182728 759.33769,0 1012.23758,0 C1393.94016,27.6565664 1280.39898,178.918983 1440.00423,235.583919 L1440.00423,400 L0,400 L0,116.204886 Z;M0.99577167,116.204886 C61.7612871,138.424342 139.929363,151.79025 235.5,156.302611 C541.97389,166.566355 760.333461,0 1013.23335,0 C1292.49289,0 1221.37079,170.167271 1441,235.583919 L1441,400 L0.99577167,400 L0.99577167,116.204886 Z;"
                      fill="freeze"
                      calcMode="spline"
                      keySplines="0 0 0.7 1; 0 0 0.7 1; 0 0 0.7 1"
                    ></animate>
                  </path>
                </svg>
              </div>
            </div>
            <div className={styles.challengeContainer}>
              <div className={styles.contentContainer}>
                <h2>Challenge</h2>
                <h3>Industry First Fitness App Designed For Seniors</h3>
                <p>
                  The SilverSneakers customer is an often underserved one in the
                  mobile application space, with very few existing mobile
                  application solutions designed with the 65+ age demographic in
                  mind. With extensive brand equity built over decades of the
                  program being offered to millions of Americans on Medicare,
                  the application had to have a more complete feature set at
                  launch in order to match the high expectations its customers
                  have of the company. These two challenges paired together
                  meant we had to plan for exactly the right set of features
                  designed in a way that their customer base would find useful
                  and find designed just for them because it was.
                </p>
              </div>
            </div>
            <div className={styles.solutionOneContainer}>
              <div
                className={`${styles.solutionOneWave} ${styles.waveContainerMobile} ${styles.hdDesktop}`}
              >
                <svg height="100%" width="100%" viewBox=" 0 0 375 70">
                  <defs>
                    <filter
                      id="f12"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <path
                    d="M0,0.866583719 L375,0.866583719 L375,69.3651939 C284.719721,54.3209419 159.719721,44.8658773 0,41 L0,0.866583719 Z"
                    fill="white"
                    filter="url(#f12)"
                  ></path>
                </svg>
              </div>
              <div
                className={`${styles.solutionOneWave} ${styles.waveContainer} ${styles.hdMobile}`}
              >
                <svg height="100%" width="100%" viewBox=" 0 0 1440 200">
                  <defs>
                    <filter
                      id="f2"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <path
                    d="M0,105.47647 C272.758895,-33.9652693 753.092228,-33.9652693 1441,105.47647 L1441,0.234505114 L0,0.234505114 L0,105.47647 Z"
                    id="Path-3-Copy"
                    fill="white"
                    filter="url(#f2)"
                  ></path>
                </svg>
              </div>
              <div className={styles.contentContainer}>
                <h2 className={styles.lgTitle}>4 Part Solution</h2>
                <div className={styles.solutionHeader}>
                  <h1 className={styles.solutionNumber}>1</h1>
                  <div className={styles.solutionContent}>
                    <h3>
                      User Testing and
                      <br />
                      Data Driven Design
                    </h3>
                    <p>
                      With over 3 million SilverSneakers-eligible customers
                      across the country, we had the unique opportunity to make
                      decisions on what the app should do and how it should work
                      by asking the individuals who would be using it. This
                      approach allowed us to prioritize features deemed most
                      valuable by the end user, and based on their technology
                      preferences, we were able to leverage user interface
                      elements from other technologies they used regularly to
                      make design decisions that felt familiar and fit easily
                      into their lives.
                    </p>
                  </div>
                </div>
                <div className={styles.inAppWorkoutContainer}></div>
              </div>
              <div className={`${styles.waveContainer} ${styles.bottom}`}>
                <svg height="100%" width="100%" viewBox=" 0 0 1440 235">
                  <defs>
                    <filter
                      id="f3"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="-20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <path
                    d="M0.99577167,116.204886 C61.7612871,138.424342 139.929363,151.79025 235.5,156.302611 C541.97389,166.566355 760.333461,0 1013.23335,0 C1292.49289,0 1221.37079,170.167271 1441,235.583919 L1441,400 L0.99577167,400 L0.99577167,116.204886 Z"
                    id="Path-3-Copy"
                    fill="white"
                    filter="url(#f1)"
                  ></path>
                </svg>
              </div>
            </div>
            <div className={styles.solutionTwoContainer}>
              <div className={styles.contentContainer}>
                <div className={styles.flexRow}>
                  <div className={styles.solutionHeader}>
                    <h1 className={styles.solutionNumber}>2</h1>
                    <div className={styles.solutionContent}>
                      <h3>
                        Usability and <br /> Interface Design
                      </h3>
                      <p>
                        The principles of strong mobile application design for
                        this population are best practices for all, so when
                        designing the interfaces for the SilverSneakers GO
                        mobile application, we were able to take existing design
                        standards and apply them in ways that kept the interface
                        uncomplicated, focused, and pleasant to use for anyone.
                        With that in mind, we also tailored the user experience
                        for the needs of our user-base by upholding high
                        accessibility standards and guiding them step-by-step
                        throughout the flows of the application.
                      </p>
                    </div>
                  </div>
                  <div className={styles.selectActivityContainer}></div>
                </div>
              </div>
            </div>
            <div className={styles.solutionThreeContainer}>
              <div className={styles.contentContainer}>
                <div className={styles.flexRow}>
                  <div className={styles.solutionHeader}>
                    <h1 className={styles.solutionNumber}>3</h1>
                    <div className={styles.solutionContent}>
                      <h3 className={styles.primary}>Gym Companion</h3>
                      <p>
                        Not only did the application need to feel like it was
                        designed for this customer, but the SilverSneakers brand
                        is synonymous with health and fitness so there had to be
                        features built into the application from day one that
                        supported this brand promise. Based on member feedback,
                        customers expected a certain set of features including
                        health and fitness programs they could follow along with
                        at home or at the gym, tailored to the unique needs of
                        the SilverSneakers member. We worked with
                        SilverSneakers-employed master trainers to design
                        workout programs built into the application that offered
                        the customer opportunities to modify any exercise based
                        on its intensity, while still following along with the
                        program's designed objectives.
                      </p>
                    </div>
                  </div>
                  <div className={styles.workoutCategoryContainer}></div>
                </div>
              </div>
            </div>
            <div className={styles.gooeyContainer}>
              <div className={`${styles.gooeyDesktop} ${styles.hdMobile}`}>
                <div className={`${styles.gooey} ${styles.hdMobile}`}></div>
                <div
                  className={`${styles.lightGooey} ${styles.hdMobile}`}
                ></div>
              </div>
              <div className={`${styles.gooeyBackground} ${styles.hdDesktop}`}>
                <div
                  className={`${styles.gooeyMobile} ${styles.hdDesktop}`}
                ></div>
              </div>
              <div className={styles.solutionFourContainer}>
                <div className={styles.contentContainer}>
                  <div className={styles.flexRow}>
                    <div className={styles.solutionHeader}>
                      <h1 className={styles.solutionNumber}>4</h1>
                      <div className={styles.solutionContent}>
                        <h3 className={styles.primary}>
                          Tracking, Logging, and Progressing
                        </h3>
                        <p>
                          The SilverSneakers member is active and mobile and it
                          came through loud and clear that there was a strong
                          desire for a single place that members could log all
                          of their activities, whether or not they involve
                          taking a SilverSneakers class, visiting a
                          participating location, or continuing their fitness
                          journey from home. We added several features to the
                          app that allow the customer to plan out their
                          workouts, track workouts done anywhere, or even add
                          reminders to their calendar to keep them on top of
                          their fitness plan. Every event added allows the
                          customer to provide some feedback and how their
                          feedback has been trending over time in their activity
                          history.
                        </p>
                      </div>
                    </div>
                    <div className={styles.activityLogContainer}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.resultsContainer}>
              <div className={styles.contentContainer}>
                <h2>Results</h2>
                <h3>
                  Real-World Feedback:
                  <br />
                  Overwhelmingly Positive
                </h3>
                <p>
                  With the SilverSneakers GO app available on both iOS and
                  Android app stores, the app has received tens of thousands of
                  downloads, has more than 4 stars on both stores, and continues
                  to receive positive feedback from the company's extensive
                  customer base. The app continues to be refined, improved, and
                  expanded upon with an ongoing partnership with Tivity Health
                  and with the great customer input we continue to receive every
                  day.
                </p>
              </div>
            </div>
            <div className={styles.testimonialsSectionContainer}>
              <div
                className={`${styles.waveContainer} ${styles.testimonialsFirstWave}`}
              >
                <svg height="100%" width="100%" viewBox=" 0 0 1440 424">
                  <defs>
                    <filter
                      id="f6"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <path
                    d="M0,203.515207 L0,0 L1440,0 L1440,423.60649 C1216.61929,315.894606 1027.86954,63.787498 712,52 C506.421422,52 282.074206,99.8315723 0,203.515207 Z"
                    id="Path-6-Copy"
                    fill="white"
                    filter="url(#f6)"
                  ></path>
                </svg>
              </div>
              <div className={styles.iphoneIsometricContainer}></div>
              <div
                className={`${styles.waveContainer} ${styles.testimonialsSecondWave}`}
              >
                <svg height="100%" width="100%" viewBox=" 0 0 1440 211">
                  <defs>
                    <filter
                      id="f7"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="-20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    filter="url(#f7)"
                  >
                    <path
                      d="M0,189.484126 C13.3054888,200.965848 55.1006309,207.498387 125.385426,209.081743 C378.83655,221.194334 839.625983,-20.2796216 1098.3251,2.4227114 C1337,21.0580605 1358.80482,128 1440,204.930382 L1440,211 L0,211 L0,189.484126 Z"
                      id="Path-7"
                      fill="white"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className={styles.testimonialContainer}>
              <div className={styles.contentContainer}>
                <div className={styles.sectionHeader}>
                  <h2>Testimonials</h2>
                  <h3>
                    Don't Just Take <br /> Our Word
                  </h3>
                </div>
                <div className={styles.testimonialCardsContainer}>
                  <div className={styles.column}>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Boby B.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        Absolutely love this app and this program. This app has
                        helped motivate me to start working out 6 days a week
                        instead of the 4 days a week i was doing prior....
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Cynthia P.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        This has changed my life , I was sitting in my house day
                        after day once I started working out I had a purpose. I
                        go every day except Sunday
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Jesse H.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        I would like to thank you for having a service like
                        this,i live with back problems,knee,legs also working
                        out really helps me get threw the pain so thank you..
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Karen J.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        User friendly and efficient with assisting me in keeping
                        up with my activities. Luv it😘😘
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Debra D.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        Love this app! I've only started using it but so far
                        it's the best one I've ever used. So easy and user
                        friendly. I love that it includes workout videos right
                        within the app. Helping to get this 60+ year old body in
                        shape! Thank you!
                      </p>
                    </div>
                  </div>
                  <div className={`${styles.column} ${styles.hdMobile}`}>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Patti B.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        This app is awesome!! Easy to navigate and very user
                        friendly.
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Jana L.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        Great app &amp; very easy to use. I'm excited to keep up
                        with my progress as I go to workout &amp; going to my
                        different locations.❤️😍🤩
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Lydia R.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        Love it. It has a lot of info. I love that you can do
                        exercise alone or in group classes. it offers a lot
                        more. Thank you
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Carol B.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        Love how it is organized. Very helpful to me in keeping
                        me on schedule and providing information.
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Thameka B.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        It's a simple and easy to use fitness app for all ages,
                        fitness levels, and tech abilities. And it includes
                        multiple options that make utilizing ur S.S. benefits, a
                        snap! *Quick access to ur digital S.S. card for facility
                        check-ins *Easily sarch for S.S. facilities and save
                        favorite locations *Schedule and log workouts, receive
                        reminders and even invite other S.S. members *Plus,
                        access to numerous pre-set exercises, workouts and
                        programs, via audio or video clips.
                      </p>
                    </div>
                  </div>
                  <div className={`${styles.column} ${styles.hdMobile}`}>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Bill S.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        Keeps me focused on exercise and goals. Great at finding
                        Silver Sneakers locations. Significant exercise videos
                        and exercise levels. Great app.
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Stanlèy T.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        This app is very helpful. If you looking for a place to
                        go to and get into physical condition or you can't make
                        it to a gym don't worry. This app will help you live
                        healthy lifestyle and get you to the perfect gym. Enjoy
                        this app.
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Lois R.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        This App is awesome and really keeps me on track with my
                        workouts. I like how easy it is to use and enter my
                        workouts for the week or month. In a way, it's keeping
                        me accountable for my exercise regime!
                      </p>
                    </div>
                    <div className={styles.testimonyCard}>
                      <div className={styles.firstRow}>
                        <div className={styles.cardTitle}>
                          <div
                            className={`${styles.left} ${styles.playStore}`}
                          ></div>
                          <div className={styles.right}>
                            <p className={styles.testimonyTitle}>Darren R.</p>
                            <div>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                              <i
                                className={`${styles.fas} ${styles.faStar} ${styles.accent}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.secondRow}>
                        The SilverSneakers Go is a AWESOME way to keep our
                        motors going! I am so grateful to still be here and glad
                        that Humana is taking care of it's members with programs
                        like this. Here's to getting healthy and making new
                        friends along the way ;)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.linkOutSection}>
              <div className={styles.clientReviewSection}>
                <div className={styles.contentContainer}>
                  <div className={styles.clientReviewContainer}>
                    <div className={styles.commentIconContainer}>
                      <svg
                        width="188px"
                        height="188px"
                        viewBox="0 0 188 188"
                        className={styles.commentIcon}
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Desktop-HD-Copy-11"
                            transform="translate(-129.000000, -7114.000000)"
                          >
                            <g
                              id="Group-5"
                              transform="translate(129.000000, 7114.000000)"
                            >
                              <circle
                                id="Oval"
                                fill="#0074C7"
                                cx="94"
                                cy="94"
                                r="94"
                              ></circle>
                              <path
                                d="M137.95,108.3 C137.95,113.100024 136.250017,116.899986 132.85,119.7 C129.449983,122.500014 125.150026,123.9 119.95,123.9 C113.549968,123.9 108.450019,122.000019 104.65,118.2 C100.849981,114.399981 98.95,108.800037 98.95,101.4 C98.95,94.599966 99.9999895,88.700025 102.1,83.7 C104.200011,78.699975 106.749985,74.5500165 109.75,71.25 C112.750015,67.9499835 115.949983,65.30001 119.35,63.3 C122.750017,61.29999 125.749987,59.900004 128.35,59.1 L136.45,71.1 C131.649976,73.300011 127.750015,76.199982 124.75,79.8 C121.749985,83.400018 120.45,87.799974 120.85,93 C126.050026,91.999995 130.199984,92.999985 133.3,96 C136.400015,99.000015 137.95,103.099974 137.95,108.3 Z M89.35,108.3 C89.35,113.100024 87.650017,116.899986 84.25,119.7 C80.849983,122.500014 76.550026,123.9 71.35,123.9 C64.949968,123.9 59.850019,122.000019 56.05,118.2 C52.249981,114.399981 50.35,108.800037 50.35,101.4 C50.35,94.599966 51.3999895,88.700025 53.5,83.7 C55.6000105,78.699975 58.149985,74.5500165 61.15,71.25 C64.150015,67.9499835 67.349983,65.30001 70.75,63.3 C74.150017,61.29999 77.149987,59.900004 79.75,59.1 L87.85,71.1 C83.049976,73.300011 79.150015,76.199982 76.15,79.8 C73.149985,83.400018 71.85,87.799974 72.25,93 C77.450026,91.999995 81.5999845,92.999985 84.7,96 C87.8000155,99.000015 89.35,103.099974 89.35,108.3 Z"
                                id="Path-1"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <h3>
                      Better Yet, <br /> Hear It From The Client
                    </h3>
                    <p>
                      Tivity Health has been working with Synapse Studios for
                      about 2 years now. Since day 1, Synapse has been a vital
                      part of the development and success of SilverSneakers GO.
                      The professionalism, quality of work and partnership that
                      Synapse provides has been nothing short of fantastic. They
                      immediately jumped in and felt like they were a part of
                      the team. They make sure to always keep you and your
                      project front of mind and make sure what you want is being
                      delivered, while offering sound advice to keep the project
                      on track. I can't say enough good things about the
                      partnership that we've forged over the last 2 years and am
                      looking forward to what we are able to develop in the
                      future.
                    </p>
                    <div className={styles.robContainer}>
                      <img src={robHeadshot} className={styles.robHeadshot} />
                      <h4 className={styles.accent}>Rob Bawol</h4>
                      <p>SilverSneakers Go Product Owner</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.waveContainer} ${styles.linkOutWave}`}>
                <svg height="100%" width="100%" viewBox=" 0 0 1440 295">
                  <defs>
                    <filter
                      id="f8"
                      width="150%"
                      height="200%"
                      y="-50%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dy="20"
                      ></feOffset>
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0"
                      ></feColorMatrix>
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feMerge>
                        <feMergeNode></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                      </feMerge>
                    </filter>
                  </defs>
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    filter="url(#f8)"
                  >
                    <path
                      d="M1440,0.5 L0,0.5 L0,84 C280,87 720,301.706222 992,294 C1264,286.293778 1229,87 1440,0.5 Z"
                      id="Path-8"
                      fill="white"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </CaseStudyLayout>
    </>
  )
}

export default SilverSneakers

export const query = graphql`
  query ssgoCaseStudyQuery {
    caseStudy: contentfulCaseStudy(slug: { eq: "ssgo" }) {
      seoTitle
      slug
      title
      excerpt
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      ...pageFields
    }
    workPage: contentfulPage(name: { eq: "page.work" }) {
      workPageSlug: slug
    }
  }
`
